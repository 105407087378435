import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "programstudi";
const baseUrl = "/" + baseName;


export default [
  {
    path: baseUrl + "/targetket",
    name: "kd-list",
    component: () => import("../views/TargetKDList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ["programstudi.view_targetketerampilan"] }
  },
  {
    path: baseUrl + "/targetket/tambah",
    name: "kd-add",
    props: { headerText: "TAMBAH TARGET KETERAMPILAN" },
    component: () => import("../views/TargetKDCreateUpdate.vue"),
    meta: { groups: ["admin_bagian"], permissions: ["programstudi.add_targetketerampilan"] }
  },
  {
    path: baseUrl + "/targetket" + APP_CONFIG.idURLPattern + "/ubah",
    name: "kd-change",
    props: { headerText: "UBAH TARGET KETERAMPILAN" },
    component: () => import("../views/TargetKDCreateUpdate.vue"),
    meta: { groups: ["admin_bagian"], permissions: ["programstudi.change_targetketerampilan"] }
  },
  {
    path: baseUrl + "/targetdiagnosis",
    name: "diag-list",
    component: () => import("../views/TargetDiagnosisList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ["programstudi.view_targetdiagnosis"] }
  },
  {
    path: baseUrl + "/targetdiagnosis/tambah",
    name: "target-diag-add",
    props: { headerText: "TAMBAH TARGET DIAGNOSIS" },
    component: () => import("../views/TargetDiagnosisCreateUpdate.vue"),
    meta: { groups: ["admin_bagian"], permissions: ["programstudi.add_targetdiagnosis"] }
  },
  {
    path: baseUrl + "/targetdiagnosis" + APP_CONFIG.idURLPattern + "/ubah",
    name: "target-diag-change",
    props: { headerText: "UBAH TARGET DIAGNOSIS" },
    component: () => import("../views/TargetDiagnosisCreateUpdate.vue"),
    meta: { groups: ["admin_bagian"], permissions: ["programstudi.change_targetdiagnosis"] }
  },
  {
    path: baseUrl + "/target-ket",
    name: "target-ket",
    component: () => import("../views/TargetKDBatchCreate.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ['programstudi.view_targetketerampilan'] }
  },
  {
    path: baseUrl + "/target-diag",
    name: "target-diag",
    component: () => import("../views/TargetDiagBatchCreate.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ['programstudi.view_targetdiagnosis'] }
  },
  {
    path: baseUrl + "/angkatan",
    name: "angkatan-list",
    component: () => import("../views/AngkatanList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ["programstudi.view_angkatan"] }
  },
  {
    path: baseUrl + "/angkatan/tambah",
    name: "angkatan-add",
    props: { headerText: "TAMBAH ANGKATAN" },
    component: () => import("../views/AngkatanCreate.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ["programstudi.add_angkatan"] }
  },
  // Susunan pattern matchin url penting!!!!!!!
  {
    path: baseUrl + "/angkatan" + APP_CONFIG.idURLPattern,
    name: "angkatan-detail",
    component: () => import("../views/AngkatanDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ["programstudi.view_angkatan"] }
  }
]
