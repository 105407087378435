import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "karyailmiah"
const baseUrl = "/" + baseName


export default [
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/KaryaIlmiahList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["karyailmiah.view_karyailmiah"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    props: { title: "Karya Ilmiah" },
    component: () => import("../views/KaryaIlmiahDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["karyailmiah.view_karyailmiah"] }
  },
  {
    path: baseUrl + "/tambah",
    name: baseName + "-add",
    component: () => import("../views/KaryaIlmiahCreateUpdate.vue"),
    props: { headerText: "TAMBAH" },
    meta: {
      groups: ["pspd"],
      permissions: ["karyailmiah.add_karyailmiah"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-change",
    component: () => import("../views/KaryaIlmiahCreateUpdate.vue"),
    props: { headerText: "UBAH" },
    meta: {
      groups: ["preceptor", "pspd"],
      permissions: ["karyailmiah.change_karyailmiah"]
    }
  }
]
