const state = {
  perawatan: null,  // digunakan ketika membuat pasien baru dari PerawatanCU ke PasienCU dan kirim kembali.
}

const mutations = {
  setState(state, payloadMap) {
    for (let field in payloadMap) {
      state[field] = payloadMap[field];
    }
  },
  resetAllState(state) {
    state.perawatan = null;
  }
}

const actions = {
  setPerawatan({ commit }, value) {
    commit("setState", { perawatan: value });
  },
  resetAllState({ commit }) {
    commit("resetAllState");
  }
}


export default {
  state,
  mutations,
  actions
}
