<template>
  <div id="app">
    <component :is="dynamicComponent" :message="message" :class="compClass" :header="header"></component>
  </div>
</template>

<script>
import { has } from "lodash";
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import "@/apps/core/assets/css/base.css";
import Buefy from "buefy";
import { SnackbarProgrammatic as Snackbar } from "buefy";
import { EventBus } from "./event-bus.js";
import FontAwesomeIcon from "@/apps/core/modules/fontAwesome.js";
import setAxiosInterceptors from "./axiosInterceptors.js";
import APP_CONFIG from "@/apps/core/modules/config.js";


Vue.component("fa", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "fa",
  defaultIconPack: "fas"
});


const COMPONENT_MAP = {
  offline: "generic-http-status",
  "not-found": "generic-http-status",
  forbidden: "generic-http-status",
  login: "plain-layout",
  "reset-password": "plain-layout",
  "request-reset-password": "plain-layout",
  "change-password": "plain-layout",
  default: "main-app-layout",
  undefined: "main-app-layout"
};

const MESSAGE_MAP = {
  offline: "Tidak bisa terhubung dengan server (Offline).",
  "not-found": "Page Not Found.",
  forbidden: "Permission Denied.",
  login: "",
  "reset-password": "",
  "request-reset-password": "",
  "change-password": "",
  default: "",
  undefined: ""
};

setAxiosInterceptors();

export default {
  name: "App",
  data() {
    return {
      refreshing: false,
      registration: null
    };
  },
  components: {
    MainAppLayout: () => import("@/apps/core/layouts/MainAppLayout.vue"),
    PlainLayout: () => import("@/apps/core/layouts/PlainLayout.vue"),
    GenericHttpStatus: () => import("@/apps/core/views/GenericHttpStatus.vue")
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isAdmin"]),
    status() {
      if (has(this.$route.meta, "status")) {
        return this.$route.meta.status;
      }

      if (has(this.$route.meta, "objectPerm")) {
        const objPerm = this.$route.meta.objectPerm;
        if (has(objPerm, "allowAdmin")) {
          if (objPerm.allowAdmin && this.isAdmin) {
            return "default";
          }
        }

        if (has(objPerm, "idField")) {
          const paramsId = this.$route.params[objPerm.idField];
          if (this.userId === paramsId) {
            return "default";
          }
        }
        return "forbidden";
      }

      return "default";
    },
    message() {
      return MESSAGE_MAP[this.status];
    },
    dynamicComponent() {
      return COMPONENT_MAP[this.status];
    },
    header() {
      return this.status === "offline" ? "WARNING" : "INFO";
    },
    compClass() {
      return this.status !== "default" ? this.status : "";
    }
  },
  methods: {
    showRefreshUI(reg) {
      // https://medium.com/@dougallrich/give-users-control-over-app-updates-in-vue-cli-3-pwas-20453aedc1f2
      // The new service worker is installed, but not yet active.
      this.registration = reg;
      const refreshAppFunc = this.refreshApp;
      Snackbar.open({
        message: "Tersedia versi baru.",
        type: "is-warning",
        actionText: "Update",
        indefinite: true,
        onAction: () => {
          refreshAppFunc();
        }
      });
    },
    refreshApp() {
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skip-waiting");
    }
  },
  created() {
    EventBus.$on("appUpdated", reg => {
      this.showRefreshUI(reg);
    });

    if (APP_CONFIG.dev) return;
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  }
};
</script>


<style lang="scss">
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

html,
body,
#app,
#mainapp {
  height: 100%;
  font-family: Ubuntu;
}

.pagination-link.is-current {
  background-color: $primary;
  border-color: $primary;
}
</style>
