import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "aktivitas";
const baseUrl = "/" + baseName;


export default [
  {
    path: baseUrl + "/tambah",
    name: baseName + "-add",
    component: () => import("../views/AktivitasPelayananCreate.vue"),
    meta: { groups: ["pspd"], 
            permissions: ["aktivitas.add_aktivitaspelayanan"] }
  },
  {
    path: baseUrl,
    name: baseName + "-keterampilan-list",
    component: () => import("../views/AktivitasKeterampilanList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], 
            permissions: ["aktivitas.view_aktivitaspelayanan", 
                          "aktivitas.view_aktivitasketerampilan"] }
  },
  {
    path: "/aktivitas-jejaring",
    name: "aktivitas-jejaring",
    component: () => import("../views/AktivitasJejaringList.vue"),
    meta: { groups: ["admin_bagian"], 
            permissions: ["aktivitas.view_aktivitaspelayanan", 
                          "aktivitas.view_aktivitasketerampilan"] }
  },
  {
    path: "/kegiatan-pspd",
    name: "kegiatan-pspd-list",
    component: () => import("../views/KegiatanPSPDList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], 
            permissions: ["aktivitas.view_kegiatanpspd"] }
  },
  {
    path: "/kegiatan-jejaring",
    name: "kegiatan-jejaring",
    component: () => import("../views/KegiatanJejaringList.vue"),
    meta: { groups: ["admin_bagian"], 
            permissions: ["aktivitas.view_kegiatanpspd"] }
  },
  {
    path: "/kegiatan-pspd/tambah",
    name: "kegiatan-pspd-add",
    component: () => import("../views/KegiatanPSPDCreateUpdate.vue"),
    meta: { groups: ["pspd"], 
            permissions: ["aktivitas.add_kegiatanpspd"] }
  },
  {
    path: "/kegiatan-pspd" + APP_CONFIG.idURLPattern + "/ubah",
    name: "kegiatan-pspd-change",
    component: () => import("../views/KegiatanPSPDCreateUpdate.vue"),
    meta: { groups: ["pspd"], 
            permissions: ["aktivitas.change_kegiatanpspd"] }
  },
  {
    path: "/kegiatan-pspd" + APP_CONFIG.idURLPattern,
    name: "kegiatan-pspd-detail",
    props: { title: "Aktivitas Harian" },
    component: () => import("../views/KegiatanPSPDDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], 
            permissions: ["aktivitas.view_kegiatanpspd"] }
  },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/departemen" + APP_CONFIG.subDeptIdURLPattern + "/keterampilan/tambah",
    name: baseName + "-keterampilan-add",
    props: { headerText: "TAMBAH", komponenTitle: "keterampilan" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], 
            permissions: ["aktivitas.add_aktivitaspelayanan", "aktivitas.add_aktivitasketerampilan"] }
  },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/departemen" + APP_CONFIG.subDeptIdURLPattern + "/keterampilan-virtual/tambah",
    name: "keterampilan-virtual-add",
    component: () => import("../views/KeterampilanVirtualCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitasketerampilan"] }
  },
  {
    path: baseUrl + "/keterampilan/tambah",
    name: "pelayanan-keterampilan-add",
    component: () => import("../views/AktivitasKeterampilanCreate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitasketerampilan"] }
  },
  {
    path: baseUrl + "/keterampilan-virtual" + APP_CONFIG.idURLPattern + "/ubah",
    name: "keterampilan-virtual-change",
    component: () => import("../views/KeterampilanVirtualCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitasketerampilan"] }
  },
  {
    path: baseUrl + "/keterampilan" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-keterampilan-change",
    props: { headerText: "UBAH", komponenTitle: "keterampilan" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], 
            permissions: ["aktivitas.change_aktivitasketerampilan"] }
  },
  {
    path: baseUrl + "/keterampilan" + APP_CONFIG.idURLPattern,
    name: baseName + "-keterampilan-detail",
    props: { title: "Keterampilan", komponenTitle: "KETERAMPILAN" },
    component: () => import("../views/AktivitasKomponenDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["aktivitas.view_aktivitasketerampilan"] }
  },
  // {
  //   path: baseUrl + "/pencapaian-keterampilan",
  //   name: "pencapaian-keterampilan-list",
  //   component: () => import("../views/PencapaianKeterampilanList.vue"),
  //   meta: { groups: ["pspd"], permissions: ["aktivitas.view_pencapaianketerampilan"] }
  // },
  {
    path: "/pencapaian/pspd",
    name: "pencapaian-pspd",
    component: () => import("../views/PencapaianPSPDList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor"], permissions: ["anggota.view_pspd"] }
  },
  {
    path: "/pencapaian/pspd" + APP_CONFIG.idURLPattern,
    name: "pencapaian-pspd-detail",
    component: () => import("../views/PencapaianPSPDDetail.vue"),
    meta: { 
      groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], 
      permissions: [
        "anggota.view_pspd", "aktivitas.view_pencapaianketerampilan", 
        "ujian.view_ujian", "karyailmiah.view_karyailmiah"] }
  },
  {
    path: "/klinis-jalan" + APP_CONFIG.perawatanIdURLPattern + baseUrl + "/tambah",
    name: baseName + "-klinis-jalan-add",
    props: { headerText: "TAMBAH AKTIVITAS", jenis: "jalan" },
    component: () => import("../views/AktivitasCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitaspelayanan"] }
  },
  {
    path: "/klinis-inap" + APP_CONFIG.perawatanIdURLPattern + baseUrl + "/tambah",
    name: baseName + "-klinis-inap-add",
    props: { headerText: "TAMBAH AKTIVITAS", jenis: "inap" },
    component: () => import("../views/AktivitasCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitaspelayanan"] }
  },
  {
    path: baseUrl + "-jalan" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-jalan-change",
    props: { headerText: "UBAH AKTIVITAS", jenis: "jalan" },
    component: () => import("../views/AktivitasCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitaspelayanan"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-change",
    props: { headerText: "UBAH AKTIVITAS", jenis: "tanpa_pasien" },
    component: () => import("../views/AktivitasCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitaspelayanan"] }
  },
  {
    path: baseUrl + "-inap" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-inap-change",
    props: { headerText: "UBAH AKTIVITAS", jenis: "inap" },
    component: () => import("../views/AktivitasCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitaspelayanan"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    props: { title: "Aktivitas Pelayanan"},
    component: () => import("../views/AktivitasDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["aktivitas.view_aktivitaspelayanan"] }
  },
  // {
  //   path: baseUrl + "/diagnosis-medis" + APP_CONFIG.idURLPattern,
  //   name: baseName + "-diag-medis-detail",
  //   props: { title: "DIAGNOSIS MEDIS", komponenTitle: "DIAGNOSIS MEDIS" },
  //   component: () => import("../views/AktivitasKomponenDetail.vue"),
  //   meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["aktivitas.view_aktivitasdiagnosismedis"] }
  // },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/departemen" + APP_CONFIG.subDeptIdURLPattern + "/diagnosis/tambah",
    name: baseName + "-diagnosis-add",
    props: { headerText: "TAMBAH", komponenTitle: "diagnosis" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitasdiagnosis"] }
  },
  {
    path: baseUrl + "/diagnosis" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-diagnosis-change",
    props: { headerText: "UBAH", komponenTitle: "diagnosis" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.change_aktivitasdiagnosis"] }
  },
  {
    path: baseUrl + "/diagnosis" + APP_CONFIG.idURLPattern,
    name: baseName + "-diagnosis-detail",
    props: { title: "Diagnosis", komponenTitle: "DIAGNOSIS" },
    component: () => import("../views/AktivitasKomponenDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["aktivitas.view_aktivitasdiagnosis"] }
  },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/pemeriksaan/tambah",
    name: baseName + "-pemeriksaan-add",
    props: { headerText: "TAMBAH", komponenTitle: "pemeriksaan" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitaspemeriksaan"] }
  },
  {
    path: baseUrl + "/pemeriksaan" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-pemeriksaan-change",
    props: { headerText: "UBAH", komponenTitle: "pemeriksaan" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.change_aktivitaspemeriksaan"] }
  },
  {
    path: baseUrl + "/pemeriksaan" + APP_CONFIG.idURLPattern,
    name: baseName + "-pemeriksaan-detail",
    props: { title: "Pemeriksaan", komponenTitle: "PEMERIKSAAN" },
    component: () => import("../views/AktivitasKomponenDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["aktivitas.view_aktivitaspemeriksaan"] }
  },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/tatalaksana/tambah",
    name: baseName + "-tatalaksana-add",
    props: { headerText: "TAMBAH", komponenTitle: "tatalaksana" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.add_aktivitastatalaksana"] }
  },
  {
    path: baseUrl + "/tatalaksana" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-tatalaksana-change",
    props: { headerText: "UBAH", komponenTitle: "tatalaksana" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["pspd"], permissions: ["aktivitas.change_aktivitastatalaksana"] }
  },
  {
    path: baseUrl + "/tatalaksana" + APP_CONFIG.idURLPattern,
    name: baseName + "-tatalaksana-detail",
    props: { title: "Tatalaksana", komponenTitle: "TATALAKSANA" },
    component: () => import("../views/AktivitasKomponenDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["aktivitas.view_aktivitastatalaksana"] }
  }
];
