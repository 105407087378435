import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import { cloneDeep } from "lodash";
import { ToastProgrammatic as Toast } from "buefy";


class ModelBase {
  constructor(modelName, model, requiredFields, nonRequiredFields, urlPath) {
    this.modelName = modelName;
    this.emptyModel = model;
    this.apiURL = `${APP_CONFIG.baseAPIURL}${urlPath}`;
    this.validity = {
      edited: false,
      validated: false
    }
    this.nonRequiredFields = nonRequiredFields;
    this.requiredFields = requiredFields;
    // this.observables = null;
    this.initObservables();
    this.commit = true;
    this.createUrl = null;
    this.updateUrl = null;
    this.payloadData = {};
  }

  setCommit(value) {
    this.commit = value;
  }

  setRequiredFields(fields) {
    return this.requiredFields = fields;
  }

  getRequiredFields() {
    return this.requiredFields;
  }

  initObservables() {
    // if (!this.observables) {
    this.observables = {
      errorMap: this.getInitialErrorFields(),
      validity: this.validity,
      loading: false
    };
    this.observables[this.modelName] = cloneDeep(this.emptyModel);
    // }
    // return this.observables;
  }

  getObservables() {
    return this.observables;
  }

  setUpdate() {
    this.observables.errorMap = this.getNoErrorMap();
    this.validity.validated = true;
    this.validity.edited = false;
  }

  setApiURL(apiURL) {
    this.apiURL = apiURL;
  }

  getApiURL() {
    return this.apiURL;
  }

  calcValidity() {
    let vals = Object.values(this.observables.errorMap);
    this.validity.validated = vals.reduce((res, val) => res && val === "", true);
    // console.log("validate");
    // console.log(this.validity.edited);
    // console.log(this.validity.validated);
    // console.log(this.observables.errorMap);
    // console.log(this.modelName);
    // console.log(this.observables[this.modelName]);
    // console.log("----------------");
  }

  getEdited() {
    return this.validity.edited;
  }

  setEdited(val) {
    this.validity.edited = val;
    this.calcValidity();
  }

  getEmptyModel() {
    return cloneDeep(this.emptyModel);
  }

  getErrorMap() {
    let errorMap = { non_field_errors: "" };
    for (let key of this.nonRequiredFields) {
      errorMap[key] = "";
    }
    return errorMap;
  }

  getInitialErrorFields() {
    let initialErrorMap = this.getErrorMap();
    for (let key of this.requiredFields) {
      initialErrorMap[key] = null;
    }
    return initialErrorMap;
  }

  getNoErrorMap() {
    let noErrorMap = this.getErrorMap();
    for (let key of this.requiredFields) {
      noErrorMap[key] = "";
    }
    return noErrorMap;
  }

  updateErrorFields(respErrorMap) {
    /* digunakan untuk load error dari response */
    this.validity.validated = false;
    this.validity.edited = false;
    for (const key of Object.keys(this.observables.errorMap)) {
      if (key in respErrorMap) {
        this.observables.errorMap[key] = respErrorMap[key].join(". ");
      } else {
        this.observables.errorMap[key] = "";
      }
    }
  }

  clearNonFieldErrors() {
    this.observables.errorMap.non_field_errors = "";
  }

  getPayload() {
    /* Method ini bisa dioverride jika perlu mentransformasi data.
       Contoh mengubah field yang valuenya "object" menjadi "id" */
    let data = JSON.parse(JSON.stringify(this.observables[this.modelName]));
    delete data.id;
    delete data.extra_data;
    const unusedFields = Object.keys(data).filter(
      field => !this.requiredFields.includes(field) &&
        !this.nonRequiredFields.includes(field));
    for (const field of unusedFields) {
      delete data[field];
    }
    data = {...data, ...this.payloadData};
    return data;
  }

  addPayloadData(data) {
    this.payloadData = data;
  }

  isLoaded() {
    return this.observables[this.modelName].id !== null;
  }

  getLoadData(data) {
    return JSON.parse(JSON.stringify(data));
  }

  delete(id, onDeleted, params = {}) {
    const url = `${this.apiURL}${id}/`;
    this.observables.loading = true;
    axios.delete(url, { params: params })
      .then((response) => {
        Toast.open("Data berhasil di hapus.");
        if (onDeleted) {
          onDeleted(response.data);
        }
      })
      .catch(() => {
        Toast.open("Gagal menghapus.");
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  load(id, onLoaded, params = {}) {
    const url = `${this.apiURL}${id}/`;
    this.observables.loading = true;
    axios.get(url, { params: params })
      .then(response => {
        let data = this.getLoadData(response.data);
        this.observables[this.modelName] = data;
        this.setUpdate();
        if (onLoaded) {
          onLoaded();
        }
      })
      .catch(error => {
        // PERLU DICEK KEMBALI !!!!!!!!
        // perlu berikan pesan error toast atau snackbar
        // console.log(error);
        Toast.open("Data gagal dimuat.");
        this.reset();
        return Promise.reject(error);
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  setCreateUrl(url) {
    this.createUrl = url;
  }

  getCreateUrl() {
    if (this.createUrl) return this.createUrl;
    return this.apiURL;
  }

  create(onSaved, params = {}) {
    const data = this.getPayload();
    const url = this.getCreateUrl();
    this.observables.loading = true;
    params.commit = this.commit;
    axios.post(url, data, { params: params })
      .then(response => {
        if (this.commit) {
          this.reset();
          Toast.open("Data berhasil disimpan.");
        }
        if (onSaved) {
          onSaved(response.data);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          this.updateErrorFields(error.response.data);
        }
        // return Promise.reject(error);
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  setUpdateUrl(url) {
    this.updateUrl = url;
  }

  getUpdateUrl() {
    if (this.updateUrl) return this.updateUrl;
    return `${this.apiURL}${this.observables[this.modelName].id}/`;
  }

  update(onSaved, params = {}) {
    const url = this.getUpdateUrl();
    const data = this.getPayload();
    this.observables.loading = true;
    axios.patch(url, data, { params: params })
      .then((response) => {
        this.validity.edited = false;
        // this.reset();
        Toast.open("Data berhasil disimpan.");
        if (onSaved) {
          onSaved(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.updateErrorFields(error.response.data);
        }
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }

  save(saveContext, onSaved, params = {}) {
    try {
      this[saveContext](onSaved, params);
    } catch (error) {
      throw `${saveContext} not a valid context!`;
    }
  }

  reset() {
    this.observables[this.modelName] = this.getEmptyModel();
    this.observables.errorMap = this.getInitialErrorFields();
    this.validity.edited = false;
    this.validity.validated = false;
  }

  resetErrors() {
    this.observables.errorMap.non_field_errors = "";
    for (let key of this.nonRequiredFields) {
      if (this.observables.errorMap[key] != "") {
        this.observables.errorMap[key] = "";
      }
    }

    for (let key of this.requiredFields) {
      if (this.observables.errorMap[key] != "") {
        this.observables.errorMap[key] = null;
      }
    }
  }

  validate(field) {
    if (this.requiredFields.includes(field)) {
      let fieldValArr = field ?
        [[field, this.observables[this.modelName][field]]] :
        Object.entries(this.observables[this.modelName]);

      for (const [fld, val] of fieldValArr) {
        let value = val;
        if (Array.isArray(val)) {
          value = val.length > 0 ? val.length : null;
        }
        // let value = Array.isArray(val) ? val.length : val;
        // console.log(value);
        this.observables.errorMap[fld] = value != null ? "" : "Harus diisi.";
      }
    } else if (this.nonRequiredFields.includes(field)) {
      this.observables.errorMap[field] = "";
    }
    this.calcValidity();
  }

}


export default ModelBase;