import APP_CONFIG from "@/apps/core/modules/config.js";
import router from "@/router";
import axios from "axios";
import { SnackbarProgrammatic as Snackbar, ToastProgrammatic as Toast } from "buefy";
import store from "./store";

let refreshingToken = false;
let retryQueue = [];

const processQueue = (error, token = null) => {
  retryQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  retryQueue = [];
}


function showSnackbar(messageText, actionText) {
  Snackbar.open({
    message: messageText,
    type: "is-warning",
    actionText: actionText,
    indefinite: true,
    onAction: () => {
      const hasOfflineClass = document.getElementsByClassName("offline").length > 0;
      if (hasOfflineClass) {
        window.location.reload();
      } else {
        store.dispatch("core/setState", { isOnline: true });
      }
    }
  });
}


function refreshToken() {
  const refreshToken = store.state.accounts.refreshToken;
  const url = APP_CONFIG.baseAPIURL + "/api/token/refresh/";
  // reset vuex account state
  store.dispatch("accounts/setToken", { refreshToken: "", accessToken: "" });
  const refreshCall = axios
    .post(url, { refresh: refreshToken })
    .then((response) => {
      const tokenPair = {
        refreshToken: response.data.refresh,
        accessToken: response.data.access
      }
      return store.dispatch("accounts/setToken", tokenPair);
    })
    .catch((error) => {
      // tampilkan halaman login karena gagal refresh token.
      let curRoute = router.currentRoute;
      if (curRoute.name !== "login") {
        let routerTo = { name: "login" };
        if (curRoute.path !== "/") {
          routerTo.query = { next: curRoute.path };
        }
        router.push(routerTo);
      }
      processQueue(error, null);
      return Promise.reject(error);
    })
    .finally(() => refreshingToken = false);
  return refreshCall;
}

function setRequestInterceptor() {
  axios.interceptors.request.use(
    config => {
      const isAuth = store.getters["accounts/isAuthenticated"];
      const targetHost = config.url.split("/")[2];
      if (isAuth && targetHost == APP_CONFIG.apiHOST) {
        const accessToken = store.state.accounts.accessToken;
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

function setResponseInterceptor() {
  axios.interceptors.response.use(
    response => response,
    error => {
      // console.log({ error: error});
      if (typeof error.response == "undefined" && store.state.core.isOnline) {
        showSnackbar("Tidak bisa terhubung dengan server.", "Retry");
        store.dispatch("core/setState", { isOnline: false });
        return Promise.reject(error);
      }
      const { config, response: { status, data } } = error;
      let accessTokenReq = data.messages ? data.messages[0].token_type == "access" : false;
      accessTokenReq = accessTokenReq || data.detail === "Kredensial autentikasi tidak disediakan.";
      if (status === 401) {
        // console.log("accessTokenReq: " + accessTokenReq);
        if (accessTokenReq) {
          // console.log("refreshingToken: " + refreshingToken);
          if (refreshingToken) {
            // console.log("retryQueue:");
            // console.log(retryQueue);
            return new Promise(function (resolve, reject) {
              retryQueue.push({ resolve, reject });
            }).then(accessToken => {
              config.headers.Authorization = `Bearer ${accessToken}`;
              // config.headers["Cache-Control"] = "max-age=0, no-cache, no-store";
              return axios.request(config);
            });
          }

          refreshingToken = true;
          // console.log("refreshing token, " + config.url);
          return refreshToken().then(accessToken => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            processQueue(null, accessToken);
            return axios.request(config);
          })
        } else {
          retryQueue = [];
          refreshingToken = false;
          store.dispatch("core/resetAllState");
          store.dispatch("accounts/resetAllState");
          // router.push({ name: "login" });
        }
      } else if (status === 403) {
        Toast.open("Tidak diperbolehkan.");
      }
      return Promise.reject(error)
    });
}

function setAxiosInterceptors() {
  setRequestInterceptor();
  setResponseInterceptor();
}


export default setAxiosInterceptors;
