const DEV = false;

let API_DOMAIN_NAME, API_PORT, API_HOST, BASE_API_URL;

if (DEV) {
  API_DOMAIN_NAME = "192.168.2.12";
  API_PORT = "8000";
  API_HOST = API_DOMAIN_NAME + ":" + API_PORT;
  BASE_API_URL = "http://" + API_HOST;
} else {
  API_DOMAIN_NAME = "pspdapi.fkunpad.id";
  API_HOST = API_DOMAIN_NAME;
  BASE_API_URL = "https://" + API_HOST;
}


const BASE_S3_URL = "https://fkunpad.s3.amazonaws.com";
const TOOLS_VIEW_WEBP_URL = "https://tools.fkunpad.id/convert/jpg";
const TOOLS_CONVERT_WEBP_URL = "https://tools.fkunpad.id/convert/webp/";


const ID_REGEX_PATTERN = "([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})";
const GROUP_ENUM = Object.freeze({
  anonymous: "anonymous",
  admin_pusat: "admin_pusat",
  admin_bagian: "admin_bagian",
  super_admin: "superuser",
  pspd: "pspd",
  preceptor: "preceptor"
})
const RESIZE_WIDTH = 800;
const RESIZE_HEIGHT = 800;
const RESIZE_QUALITY = 0.8;


const APP_CONFIG = Object.freeze({
  dev: DEV,
  apiHOST: API_HOST,
  baseS3Url: BASE_S3_URL,
  brandText: "PSPD",
  subBrandText: "Sistem Pencatatan Logbook",
  baseAPIURL: BASE_API_URL,
  groupEnum: GROUP_ENUM,
  aktivitasIdURLPattern: `/:aktivitasId${ID_REGEX_PATTERN}`,
  idURLPattern: `/:id${ID_REGEX_PATTERN}`,
  kompIdURLPattern: `/:kompId${ID_REGEX_PATTERN}`,
  perawatanIdURLPattern: `/:perawatanId${ID_REGEX_PATTERN}`,
  publicPages: ["/login", `/reset-password/${ID_REGEX_PATTERN}`, "/request-reset-password"],
  pagination: {
    query_param: "limit",
    limit: 10
  },
  resizeHeight: RESIZE_HEIGHT,
  resizeQuality: RESIZE_QUALITY,
  resizeWidth: RESIZE_WIDTH,
  subDeptIdURLPattern: `/:subDeptId${ID_REGEX_PATTERN}`,
  toolsConvertWebpUrl: TOOLS_CONVERT_WEBP_URL,
  toolsViewWebpUrl: TOOLS_VIEW_WEBP_URL,
})


export default APP_CONFIG;
