// import APP_CONFIG from "@/apps/core/modules/config.js";


const state = {
  isOnline: true,
};

const mutations = {
  setState(mstate, payloadMap) {
    for (let field in payloadMap) {
      mstate[field] = payloadMap[field];
    }
  },
  resetAllState(mstate) {
    for (let field in mstate) {
      if (field === "isOnline") continue;
      mstate[field] = false;
    }
  }
};

const actions = {
  setState({ commit }, payloadMap) {
    commit("setState", payloadMap);
  },
  resetAllState({ commit }) {
    commit("resetAllState");
  }
};


export default {
  state,
  mutations,
  actions
};
