import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleLeft,
  faAngleRight,
  // faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBed,
  // faCalendar,
  faCalendarDay,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronRight,
  // faChevronUp,
  faDownload,
  faEdit,
  faFileExcel,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFilter,
  // faFolderPlus,
  // faHome,
  faImage,
  faInfoCircle,
  // faImages,
  // faLabel,
  faLock,
  faMars,
  faMinus,
  faPlus,
  faSave,
  // faSyncAlt,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faVenus,
  faVideo,
  faUpload,
  faUser,
  // faUserSlash,
  faWalking
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngleLeft,
  faAngleRight,
  // faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBed,
  // faCalendar,
  faCalendarDay,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronRight,
  // faChevronUp,
  faCircle,
  faDownload,
  faEdit,
  faFileExcel,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFilter,
  // faFolderPlus,
  // faHome,
  faImage,
  faInfoCircle,
  // faImages,
  // faLabel,
  faLock,
  faMars,
  faMinus,
  faPlus,
  faSave,
  // faSyncAlt,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faVenus,
  faVideo,
  faUpload,
  faUser,
  // faUserSlash,
  faWalking
);


export default FontAwesomeIcon;
