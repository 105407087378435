import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "penyakit";
const baseUrl = "/" + baseName;


export default [
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/diagnosis/tambah",
    name: "subdept-diagnosis-add",
    props: { headerText: "diagnosis", jenisKomponen: "diagnosis" },
    component: () => import("../views/DiagnosisSubDeptCreate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "pspd"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_diagnosis"]
    }
  },
  {
    path: baseUrl + "/diagnosis/tambah",
    name: "diagnosis-add",
    props: { headerText: "diagnosis", jenisKomponen: "diagnosis" },
    component: () => import("../views/DiagnosisCreate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "pspd"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_diagnosis"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/keterampilan/tambah",
    name: "subdept-keterampilan-add",
    props: { headerText: "keterampilan", jenisKomponen: "keterampilan" },
    component: () => import("../views/KeterampilanCreate.vue"),
    meta: {
      groups: ["pspd"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_keterampilan"]
    }
  },
  {
    path: baseUrl + "/keterampilan/tambah",
    name: "keterampilan-add",
    props: { headerText: "keterampilan", jenisKomponen: "keterampilan" },
    component: () => import("../views/KeterampilanCreate.vue"),
    meta: {
      groups: ["pspd"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_keterampilan"]
    }
  },
  {
    path: baseUrl + "/pemeriksaan/tambah",
    name: "pemeriksaan-add",
    props: { headerText: "pemeriksaan", jenisKomponen: "pemeriksaan" },
    component: () => import("../views/ConcreteKomponenCreate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "pspd"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_pemeriksaan"]
    }
  },
  {
    path: baseUrl + "/tatalaksana/tambah",
    name: "tatalaksana-add",
    props: { headerText: "tatalaksana", jenisKomponen: "tatalaksana" },
    component: () => import("../views/ConcreteKomponenCreate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "pspd"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_tatalaksana"]
    }
  }
]
