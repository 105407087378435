
const state = {
  aktLokasi: null,
  aktKomponen: null,
  aktPSPD: null,
  kegiatanLokasi: null
}

const mutations = {
  setState(state, payloadMap) {
    for (let field in payloadMap) {
      state[field] = payloadMap[field];
    }
  },
  resetAllState(state) {
    state.aktLokasi = null;
    state.aktKomponen = null;
    state.aktPSPD = null;
    state.kegiatanLokasi = null;
  }
}

const actions = {
  setAktKomponen({ commit }, value) {
    commit("setState", { aktKomponen: value });
  },
  setAktPSPDState({ commit }, value) {
    commit("setState", { aktPSPD: value });
  },
  setAktLokasi({ commit }, value) {
    commit("setState", { aktLokasi: value });
  },
  setKegiatanLokasi({ commit }, value) {
    commit("setState", { kegiatanLokasi: value });
  },
  resetAllState({ commit }) {
    commit("resetAllState");
  }
}


export default {
  state,
  mutations,
  actions
}
