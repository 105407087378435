import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

class AnggotaBase extends ModelBase {
  constructor(modelName, model, requiredFields, nonRequiredFields, urlPath) {
    let modelBase = {
      id: null,
      nama: "",
      inisial: "",
      user: { email: "" },
      no_hp: "",
      alamat: "",
      catatan: ""
    }
    Object.assign(model, modelBase);
    let requiredFieldsBase = ["nama", "email", "user"];
    requiredFields = requiredFields.concat(requiredFieldsBase);
    let nonRequiredFieldsBase = ["inisial", "no_hp", "alamat", "catatan"];
    nonRequiredFields = nonRequiredFields.concat(nonRequiredFieldsBase);
    super(modelName, model, requiredFields, nonRequiredFields, urlPath);
  }

  getUpdateUrl() {
    if (this.observables.tipe == "admin") return super.getUpdateUrl();
    return `${APP_CONFIG.baseAPIURL}/anggota/${this.observables.tipe}/${this.observables.anggota.id}/`;
  }

  getInitialErrorFields() {
    let errorMap = super.getInitialErrorFields();
    errorMap.email = null;
    errorMap.user = "";
    return errorMap;
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.user = { email: data.user };
    return tdata;
  }

  validate(field) {
    if (field === "email") {
      const value = this.observables[this.modelName].user[field]
      this.observables.errorMap[field] = value ? "" : "Harus diisi.";
      this.observables.errorMap.user = "";
      this.calcValidity();
    } else {
      super.validate(field);
    }
  }
}

export default AnggotaBase;