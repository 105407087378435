import AnggotaBase from "./anggotaBase.js"

const modelName = "anggota";
const modelPath = "/anggota/";

class Anggota extends AnggotaBase {
  constructor() {
    super(modelName, {}, [], [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.tipe = "admin";
  }

  getPayload() {
    let data = super.getPayload();
    delete data.sub_departemen;
    return data;
  }
}

export default Anggota;