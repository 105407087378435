import APP_CONFIG from "@/apps/core/modules/config.js";
import Anggota from "../models/anggotaCU.js";


const baseName = "anggota"
const baseUrl = "/" + baseName


export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"],
      permissions: [
        "perawatan.view_perawatan", "ujian.view_ujian",
        "karyailmiah.view_karyailmiah"
      ]
    }
  },
  {
    path: baseUrl + "/me",
    name: baseName + "-me",
    props: { id: "me" },
    component: () => import("../views/AnggotaDetail.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"],
      permissions: ["anggota.view_anggota"]
    }
  },
  {
    path: "/pspd/kelompok/tambah",
    name: "kelompok-pspd-add",
    component: () => import("../views/KelompokCreate.vue"),
    meta: {
      groups: ["admin_pusat"],
      permissions: ["anggota.add_pspdtag"]
    }
  },
  {
    path: "/pspd/kelompok",
    name: "kelompok-pspd",
    component: () => import("../views/KelompokList.vue"),
    meta: {
      groups: ["admin_pusat"],
      permissions: ["anggota.view_pspdtag"]
    }
  },
  {
    path: "/pspd/kelompok" + APP_CONFIG.idURLPattern + "/ubah",
    name: "kelompok-change",
    component: () => import("../views/KelompokUpdate.vue"),
    meta: {
      groups: ["admin_pusat"],
      permissions: ["anggota.change_pspdtag"]
    }
  },
  {
    path: "/pspd/kelompok" + APP_CONFIG.idURLPattern,
    name: "kelompok-detail",
    component: () => import("../views/KelompokDetail.vue"),
    meta: {
      groups: ["admin_pusat"],
      permissions: ["anggota.view_pspdtag"]
    }
  },
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/AnggotaList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ["anggota.view_anggota"] }
  },
  {
    path: "/pspd/tambah",
    name: "pspd-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/PSPDCreateUpdate.vue"),
    meta: {
      groups: ["admin_pusat"],
      permissions: ["anggota.add_anggota", "anggota.add_pspd"]
    }
  },
  {
    path: "/preceptor/tambah",
    name: "preceptor-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/PreceptorCreateUpdate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian"],
      permissions: ["anggota.add_anggota", "anggota.add_preceptor"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-change",
    props: {
      headerText: "UBAH",
      anggotaMdl: new Anggota()
    },
    component: () => import("../views/CommonAnggotaCreateUpdate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian"],
      permissions: ["anggota.change_anggota"]
    }
  },
  {
    path: "/preceptor" + APP_CONFIG.idURLPattern + "/ubah",
    name: "preceptor-change",
    props: { headerText: "UBAH" },
    component: () => import("../views/PreceptorCreateUpdate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "preceptor"],
      permissions: ["anggota.change_anggota", "anggota.change_preceptor"]
    }
  },
  {
    path: "/pspd" + APP_CONFIG.idURLPattern + "/ubah",
    name: "pspd-change",
    props: { headerText: "UBAH" },
    component: () => import("../views/PSPDCreateUpdate.vue"),
    meta: {
      groups: ["admin_pusat", "pspd"],
      permissions: ["anggota.change_anggota", "anggota.change_pspd"],
      objectPerm: { allowAdmin: true, idField: "id" }
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    component: () => import("../views/AnggotaDetail.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian"], permissions: ["anggota.view_anggota"] }
  }
]
