const state = {
    karyaIlmiah: null
  }
  
  const mutations = {
    setState(state, payloadMap) {
      for (let field in payloadMap) {
        state[field] = payloadMap[field];
      }
    },
    resetAllState(state) {
      state.karyaIlmiah = null;
    }
  }
  
  const actions = {
    setKaryaIlmiah({ commit }, value) {
      commit("setState", { karyaIlmiah: value });
    },
    resetAllState({ commit }) {
      commit("resetAllState");
    }
  }
  
  
  export default {
    state,
    mutations,
    actions
  }
  