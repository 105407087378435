import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "stase"
const baseUrl = "/" + baseName


export default [
  {
    path: baseUrl + APP_CONFIG.idURLPattern + '/ubah',
    name: baseName + "-change",
    props: { headerText: "UBAH" },
    component: () => import("../views/StaseCreateUpdate.vue"),
    meta: { groups: ["admin_pusat"], permissions: ["stase.change_stase"] }
  },
  {
    path: "/jadwal/departemen",
    name: "jadwal-departemen-list",
    component: () => import("../views/JadwalDepartemenList.vue"),
    meta: { groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], permissions: ["stase.view_jadwalsubdepartemen"] }
  },
  {
    path: "/jadwal/departemen/tambah",
    name: "jadwal-departemen-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/JadwalDepartemenCreateUpdate.vue"),
    meta: {
      groups: ["admin_bagian"],
      permissions: ["stase.add_jadwalsubdepartemen"]
    }
  },
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/StaseList.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"],
      permissions: ["stase.view_stase"]
    }
  },
  {
    path: baseUrl + "/tambah",
    name: baseName + "-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/StaseCreateUpdate.vue"),
    meta: {
      groups: ["admin_pusat"],
      permissions: ["stase.add_stase"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/pspd",
    name: baseName + "-pspd-add",
    component: () => import("../views/StasePSPDBatchCreate.vue"),
    meta: {
      groups: ["admin_pusat", "admin_bagian"],
      permissions: ["stase.add_stasepspd"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    props: { title: "Stase"},
    component: () => import("../views/StaseDetail.vue"),
    meta: { 
      groups: ["admin_pusat", "admin_bagian", "preceptor", "pspd"], 
      permissions: ["stase.view_stase", "stase.view_stasepspd"] }
  },
  {
    path: "/pencapaian/nilai",
    name: "pencapaian-nilai",
    component: () => import("../views/PencapaianNilaiList.vue"),
    meta: { 
      groups: ["admin_pusat", "admin_bagian"], 
      permissions: ["stase.view_stase"] }
  },
  {
    path: "/pencapaian/nilai" + APP_CONFIG.idURLPattern,
    name: "pencapaian-nilai-detail",
    component: () => import("../views/PencapaianNilaiDetail.vue"),
    meta: { 
      groups: ["admin_pusat", "admin_bagian"], 
      permissions: ["stase.view_stase", "stase.view_stasepspd"] }
  },
]
